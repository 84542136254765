import React from 'react';
/** Components */
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';

function Info() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Info</h1>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col sm={6}>
          <h4>Conference Policies</h4>
        </Col>
        <Col sm={6}>
          <h4>Conference Deadlines</h4>
          <ul>
            <li>Early Bird Registration - Some Date</li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
}

export default Info;

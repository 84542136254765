import React from 'react';
/** Components */
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';

function Present() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Apply to Co-Present</h1>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <p>
            If you would like to apply for the position to co-present a class
            with one of our instructors, please fill out the form below. We will
            review all applications and notify you of our decision. If you have
            any questions, please contact us at
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Present;

import React from 'react';

function Schedule() {
  return (
    <div>
      <h1>Schedule</h1>
    </div>
  );
}

export default Schedule;

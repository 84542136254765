import React from 'react';
/** Components */
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';

function Register() {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="pirateHeader">REGISTER</h1>
          <hr />
        </Col>
      </Row>
      <Row className="pb-2">
        <Col sm={4} className="d-flex justify-content-center mb-3">
          <Card className="hotelCard">
            <Card.Body>
              <Card.Title className="text-center pirateHeader">
                <h2>Early Bird Registration</h2>
              </Card.Title>
              <Card.Text className="text-center">
                <p
                  className="piratePrice"
                  style={{ fontSize: '72px', color: 'white' }}>
                  $495.00
                </p>
                <h4 className=" pb-5" style={{ color: 'white' }}>
                  *per registrant
                </h4>
                <p>*On or Before 10/25/2024.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4} className="flex-column ">
          <Row className="pt-5 pb-5 d-flex justify-content-center mb-3">
            <Col sm={6}>
              <a
                href="https://asbworks.myschoolcentral.com"
                target="_blank"
                rel="noreferrer">
                <Button variant="primary" style={{ width: '150px' }}>
                  Register Online
                </Button>
              </a>
            </Col>
          </Row>
          <Row className="pb-2 d-flex justify-content-center mb-3">
            <Col sm={6}>
              <a
                href="https://www.graystep.com/asbworks/conference/ASBWorks%20Conference%20Packet%202025.pdf"
                target="_blank"
                rel="noreferrer">
                <Button variant="primary" style={{ width: '150px' }}>
                  Register By Mail
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
        <Col sm={4} className="d-flex justify-content-center mb-3">
          <Card className="hotelCard">
            <Card.Body>
              <Card.Title className="text-center pirateHeader">
                <h2>Standard Registration</h2>
              </Card.Title>
              <Card.Text className="text-center">
                <p
                  className="piratePrice"
                  style={{ fontSize: '72px', color: 'white' }}>
                  $525.00
                </p>
                <h4 className=" pb-5" style={{ color: 'white' }}>
                  *per registrant
                </h4>
                <p>*After 10/25/2024.</p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row className="pb-5">
        <Col className="d-flex justify-content-center">
          <Alert variant="info" style={{ maxWidth: '780px' }}>
            {/* wrapping icon in a span with a class to control padding */}
            <span className="d-flex">
              <InfoIcon className="mt-1 me-1 fs-3" />
              <h3>Guests</h3>
            </span>
            <p>
              Early-Bird Registration for the 2025 ASBWorks User Conference ends
              on October 25th, 2024. Registration prices increase to the
              Standard Registration on October 26th, 2024. Registrations
              received on or after October 26th will be billed for the Standard
              Registration price. Early-Bird Registrations with check payments
              postmarked by October 25th, 2024 will be accepted.{' '}
            </p>
            <p>
              Registration closes on January 17th, 2025. Onsite registration may
              be available during the event but are subject to availability. If
              you have any questions regarding onsite registration, please
              submit them to conference@asbworks.com.{' '}
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default Register;

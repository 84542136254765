import React from 'react';
/** Components */
import { Container, Row, Col, Card, Button, Alert } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';

function Hotel() {
  return (
    <Container>
      <Row>
        <Col>
          <h1 className="pirateHeader">HOTEL</h1>
          <hr />
        </Col>
      </Row>
      <Row className="pb-2">
        <Col sm={6} className="d-flex justify-content-center mb-3">
          <Card className="hotelCard">
            <Card.Body>
              <Card.Title className="text-center pirateHeader">
                <h2>The Disneyland Hotel</h2>
              </Card.Title>
              <Card.Text className="text-center">
                <p
                  className="piratePrice"
                  style={{ fontSize: '72px', color: 'white' }}>
                  $294.00
                </p>
                <p>
                  *Does not include parking & fees. Once sold out, rooms will be
                  available at the prevailing rate.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} className="d-flex justify-content-center mb-3">
          <Card className="hotelCard">
            <Card.Body>
              <Card.Title className="text-center pirateHeader">
                <h2>The Grand Californian</h2>
              </Card.Title>
              <Card.Text className="text-center">
                <p
                  className="piratePrice"
                  style={{ fontSize: '72px', color: 'white' }}>
                  $359.00
                </p>
                <p>
                  *Does not include parking & fees. Once sold out, rooms will be
                  available at the prevailing rate.
                </p>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="pb-4">
        <Col className="d-flex justify-content-center">
          <a
            href="https://www.mydisneygroup.com/GDAA25C"
            target="_blank"
            rel="noreferrer">
            <Button variant="primary">Book Now</Button>
          </a>
        </Col>
      </Row>
      <Row className="pb-5">
        <Col className="d-flex justify-content-center">
          <Alert variant="info" style={{ maxWidth: '780px' }}>
            {/* wrapping icon in a span with a class to control padding */}
            <span className="d-flex">
              <InfoIcon className="mt-1 me-1 fs-3" />
              <h3>Guests</h3>
            </span>
            <p>
              ASBWorks is hosting its 12th Annual User Conference at the
              Disneyland Resort in Anaheim, CA. Conference Events will be held
              in the Disneyland Hotel Convention Center, throughout the hotel
              grounds, and inside Downtown Disney.
            </p>
            <p>
              We have secured a block of rooms for our guests that are available
              for booking at the Disneyland Resort, and Disney’s Grand
              Californian Hotel.
            </p>
            <p>
              Please make sure to book your hotel room early, as they will sell
              out.*
            </p>
            <p>
              <sub>
                *Once sold out, rooms will be available at the prevailing rates.
              </sub>
            </p>
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default Hotel;

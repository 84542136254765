import React from 'react';
/** Components */
import { Container, Row, Col, Alert, Button } from 'react-bootstrap';
import InfoIcon from '@mui/icons-material/Info';

function OnlineRegistration() {
  return (
    <Container>
      <Row>
        <Col>
          <h1>Online Registration</h1>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col sm={7} className="pb-3">
          <p>
            Please continue on to our online registration to register with a
            credit card on the ASBWorks webstore.
          </p>
          <Button variant="primary">Register Online</Button>
        </Col>
        <Col sm={5}>
          <Alert variant="info">
            {/* wrapping icon in a span with a class to control padding */}
            <span className="d-flex">
              <InfoIcon className="mt-1 me-1 fs-3" />
              <h3>Guests</h3>
            </span>
            If you are bringing a guest, please make sure to register them as
            well while filling out the form.
          </Alert>
        </Col>
      </Row>
    </Container>
  );
}

export default OnlineRegistration;

import React from 'react';

function Guest() {
  return (
    <div>
      <h1>Guest</h1>
    </div>
  );
}

export default Guest;

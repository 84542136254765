/** Router */
import { Routes, Route } from 'react-router-dom';
/** Pages */
import MainContent from '../pages/MainContent';
import Hotel from '../pages/Hotel';
import Schedule from '../pages/Schedule';
import Register from '../pages/Register';
import OnlineRegistration from '../pages/OnlineRegistration';
import MailRegistration from '../pages/MailRegistration';
import Present from '../pages/Present';
import Info from '../pages/Info';
import Guest from '../pages/Guest';

function PageRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<MainContent />} />
      <Route index path="/register" element={<Register />} />
      <Route path="register-online" element={<OnlineRegistration />} />
      <Route path="register-mail" element={<MailRegistration />} />
      <Route path="hotel" element={<Hotel />} />
      <Route path="schedule" element={<Schedule />} />
      <Route path="guest-attendance" element={<Guest />} />
      <Route path="info" element={<Info />} />
      <Route path="present" element={<Present />} />
    </Routes>
  );
}

export default PageRoutes;

import { React, useState, useEffect } from 'react';
/** Components */
//https://www.npmjs.com/package/react-pro-sidebar
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  sidebarClasses,
  menuClasses,
} from 'react-pro-sidebar';
/** Utils */
import { Link, useLocation } from 'react-router-dom';
/** Icons */
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import WifiIcon from '@mui/icons-material/Wifi';
import MailIcon from '@mui/icons-material/Mail';
import EventNoteIcon from '@mui/icons-material/EventNote';
import HotelIcon from '@mui/icons-material/Hotel';
import PeopleIcon from '@mui/icons-material/People';
import HelpIcon from '@mui/icons-material/Help';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import HomeIcon from '@mui/icons-material/Home';
/** Assets */
import sidebarBackground from '../assets/sidebarBackground.png';

function SideMenu() {
  const isMobileDevice =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    );

  const isReady = false;

  const [collapsed, setCollapsed] = useState(isMobileDevice ? true : false);
  const [selectedLink, setSelectedLink] = useState('home');
  const location = useLocation();

  useEffect(() => {
    setSelectedLink(location.pathname);
  }, [location]);

  return (
    <Sidebar
      style={{ border: 0, color: '#f9ea35', textShadow: '1px 1px 2px #000000' }}
      backgroundColor="transparent"
      image={sidebarBackground}
      collapsed={collapsed}
      width={isMobileDevice ? '100vw' : '270px'}
      rootStyles={{
        [`.${sidebarClasses.container}`]: {
          height: '100vh',
          maxWidth: '250px',
        },
        [`.${menuClasses.subMenuContent}`]: {
          backgroundColor: 'transparent',
        },
      }}>
      <Menu closeOnClick={isMobileDevice ? true : false}>
        <MenuItem
          icon={collapsed ? <MenuIcon /> : <MenuOpenIcon />}
          onClick={() => setCollapsed(!collapsed)}>
          ASB Conference
        </MenuItem>
        <MenuItem
          onClick={() =>
            isMobileDevice && collapsed === false ? setCollapsed(true) : null
          }
          component={<Link to="/" />}
          icon={
            <HomeIcon color={selectedLink === '/' ? 'primary' : 'inherit'} />
          }>
          Home
        </MenuItem>
        <MenuItem
          onClick={() =>
            isMobileDevice && collapsed === false ? setCollapsed(true) : null
          }
          component={<Link to="/register" />}
          icon={
            <PersonAddAlt1Icon
              color={selectedLink === '/register' ? 'primary' : 'inherit'}
            />
          }>
          Register
        </MenuItem>

        <MenuItem
          onClick={() =>
            isMobileDevice && collapsed === false ? setCollapsed(true) : null
          }
          component={<Link to="/hotel" />}
          icon={
            <HotelIcon
              color={selectedLink === '/hotel' ? 'primary' : 'inherit'}
            />
          }>
          Hotel
        </MenuItem>
        {isReady ? (
          <>
            <SubMenu
              label="Register"
              component={<Link to="/register" />}
              open={
                selectedLink === '/register-online' ||
                selectedLink === '/register-mail' ||
                selectedLink === '/register'
              }
              icon={
                <PersonAddAlt1Icon
                  color={
                    selectedLink === '/register-online' ||
                    selectedLink === '/register-mail' ||
                    selectedLink === '/register'
                      ? 'primary'
                      : 'inherit'
                  }
                />
              }>
              <MenuItem
                onClick={() =>
                  isMobileDevice && collapsed === false
                    ? setCollapsed(true)
                    : null
                }
                component={<Link to="/register-online" />}
                icon={
                  <WifiIcon
                    color={
                      selectedLink === '/register-online'
                        ? 'primary'
                        : 'inherit'
                    }
                  />
                }>
                Register Online
              </MenuItem>
              <MenuItem
                onClick={() =>
                  isMobileDevice && collapsed === false
                    ? setCollapsed(true)
                    : null
                }
                component={<Link to="/register-mail" />}
                icon={
                  <MailIcon
                    color={
                      selectedLink === '/register-mail' ? 'primary' : 'inherit'
                    }
                  />
                }>
                Register By Mail
              </MenuItem>
            </SubMenu>
            <MenuItem
              onClick={() =>
                isMobileDevice && collapsed === false
                  ? setCollapsed(true)
                  : null
              }
              component={<Link to="/schedule" />}
              icon={
                <EventNoteIcon
                  color={selectedLink === '/schedule' ? 'primary' : 'inherit'}
                />
              }>
              Schedule of Events
            </MenuItem>
            <MenuItem
              onClick={() =>
                isMobileDevice && collapsed === false
                  ? setCollapsed(true)
                  : null
              }
              component={<Link to="/guest-attendance" />}
              icon={
                <PeopleIcon
                  color={
                    selectedLink === '/guest-attendance' ? 'primary' : 'inherit'
                  }
                />
              }>
              Guest Attendance
            </MenuItem>
            <MenuItem
              onClick={() =>
                isMobileDevice && collapsed === false
                  ? setCollapsed(true)
                  : null
              }
              component={<Link to="/info" />}
              icon={
                <HelpIcon
                  color={selectedLink === '/info' ? 'primary' : 'inherit'}
                />
              }>
              Deadlines/Policies
            </MenuItem>
            <MenuItem
              onClick={() =>
                isMobileDevice && collapsed === false
                  ? setCollapsed(true)
                  : null
              }
              component={<Link to="/present" />}
              icon={
                <ConnectWithoutContactIcon
                  color={selectedLink === '/present' ? 'primary' : 'inherit'}
                />
              }>
              Apply to Co-Present
            </MenuItem>
          </>
        ) : null}
      </Menu>
    </Sidebar>
  );
}

export default SideMenu;

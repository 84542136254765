/** Components */
import SideMenu from './components/SideMenu';
import PageRoutes from './routes/PageRoutes';
/** Utils */
import { Outlet } from 'react-router-dom';

function App() {
  return (
    /**
     * Wrapping everything in this div so when the SideMenu is toggled, the
     * content will move to the right.
     */
    <div
      style={{
        display: 'flex',
        height: '100%',
        direction: 'ltr',
      }}>
      <SideMenu />
      <PageRoutes />
      <Outlet />
    </div>
  );
}

export default App;

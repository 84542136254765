import React from 'react';
/** Components */
import { Container, Row, Col, Image } from 'react-bootstrap';
/** assets */
import conferenceLogo from '../assets/conferenceLogo.png';

function MainContent() {
  return (
    <>
      <Container fluid>
        <Row className="h-100 align-items-center text-center">
          <Col>
            <Image fluid src={conferenceLogo} alt="Conference Logo" />
            <h1
              className="pirateHeader"
              style={{ color: '#ff0003', textShadow: 'none' }}>
              ASBWorks 2025 User Conference
            </h1>
            <h3
              className="pirateHeader"
              style={{ color: '#ff0003', textShadow: 'none' }}>
              January 22-24, 2025
            </h3>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MainContent;
